<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>Package</h3>
      </div>
      <hr class="hline" />
      <div class="columns">
        <div class="column is-3-desktop">
          <div class="box price-box slide-down">
            <h3 class="price-title">Small</h3>
            <div class="detail">
              <div class="post">
                <h3>01</h3>
                <span>Post / Week</span>
              </div>
              <hr />
              <h3 class="old-price">0</h3>
              <h3 class="price">200,000<span>LAK</span></h3>
            </div>
            <button class="button buy-btn" @click="selected = true">
              Select
            </button>
          </div>
        </div>
        <div class="column is-3-desktop">
          <div class="box rec price-box slide-up">
            <h3 class="price-title">Medium</h3>
            <div class="detail">
              <div class="post">
                <h3>03</h3>
                <span>Post / Week</span>
              </div>
              <hr />
              <h3 class="old-price">600,000<span>LAK</span></h3>
              <h3 class="price">450,000<span>LAK</span></h3>
              <button class="button buy-btn" @click="select = !select">
                Select
              </button>
            </div>
          </div>
        </div>
        <div class="column is-3-desktop">
          <div class="box price-box slide-left">
            <h3 class="price-title">Large</h3>
            <div class="detail">
              <div class="post">
                <h3>05</h3>
                <span>Post / Week</span>
              </div>
              <hr />
              <h3 class="old-price">1,000,000<span>LAK</span></h3>
              <h3 class="price">650,000<span>LAK</span></h3>
            </div>
            <button class="button buy-btn">Select</button>
          </div>
        </div>
        <div class="column is-3-desktop">
          <div class="box price-box slide-right">
            <h3 class="price-title">Extra Large</h3>
            <div class="detail">
              <div class="post">
                <h3>10</h3>
                <span>Post / Week</span>
              </div>
              <hr />
              <h3 class="old-price">2,000,000<span>LAK</span></h3>
              <h3 class="price">1,200,000<span>LAK</span></h3>
            </div>
            <button class="button buy-btn">Select</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// Package card
.price-box {
  cursor: pointer;
  padding: 30px;
  color: var(--text-color);
  height: 100%;
  text-align: center;
  border: 1px solid var(--border-color);
  &.rec {
    border: 3px solid var(--primary-color);
  }
  .price-title {
    background-color: var(--alert-color);
    display: inline-block;
    padding: 3px 15px 0;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }
  .detail {
    margin-top: 10px;
    .post {
      margin: 20px 0;
      h3 {
        color: var(--primary-color);
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
      }
      span {
        margin: 0;
        font-size: 16px;
      }
    }
    hr {
      background-color: var(--grey-color);
      height: 1px;
      margin: 0 auto;
      width: 50px;
    }
    .price {
      font-size: 18px;
      line-height: 1;
      span {
        margin-left: 5px;
      }
    }
    .old-price {
      margin-top: 20px;
      margin-bottom: 5px;
      display: inline-block;
      position: relative;
      color: #777;
      font-size: 16px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 48%;
        width: 100%;
        left: 0;
        height: 1px;
        background-color: var(--alert-color);
      }
      span {
        margin-left: 5px;
      }
    }
  }
  .buy-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    &:focus {
      box-shadow: none;
      box-shadow: unset;
      border: none;
    }
  }
}

.field {
  p {
    margin-top: 5px;
    font-size: 14px;
  }
}
// Package card
</style>
